import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import { Swipe, SwipeItem,Icon,} from 'vant';
import axios from 'axios'
import './utils/request'
import 'element-ui/lib/theme-chalk/index.css'
import api from '@/api.js'  
import * as echarts from 'echarts'//引入echarts5.0新写法，否则会报错 

import { DatetimePicker,Popup,Button,Picker,Toast,Tab,Tabs,Sticky,ActionSheet,Cell,Divider,Search,Calendar } from 'vant';

Vue.use(DatetimePicker).use(Popup).use(Button).use(Picker).use(Swipe).use(SwipeItem).use(Icon).use(Toast).use(Tab).use(Tabs).use(Sticky).use(ActionSheet).use(Cell).use(Divider).use(Search).use(Calendar);
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.prototype.$axios = axios
Vue.prototype.$api = api//将url挂载至全局


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

