import Vue from 'vue'
import Router from 'vue-router'
import guard from './guard'

Vue.use(Router)

const routes = [
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test'),
    meta: {
      title: '医保刷卡'
    }
  },
  {
    path: '/atOnce',
    name: 'atOnce',
    component: () => import('@/views/atOnce'),
    meta: {
      title: '码上放心'
    }
  },
  {
    path: '/class',
    name: 'class',
    component: () => import('@/views/class'),
    meta: {
      title: '值班收费'
    }
  },
  {
    path: '/car',
    name: 'car',
    component: () => import('@/views/car'),
    meta: {
      title: '车队收费'
    }
  },
  {
    path: '/jcPage',
    name: 'jcPage',
    component: () => import('@/views/jcPage'),
    meta: {
      title: '检查收费'
    }
  },
  {
    path: '/zlypPage',
    name: 'zlypPage',
    component: () => import('@/views/zlypPage'),
    meta: {
      title: '诊疗/药品收费'
    }
  },
  {
    path: '/arehousingScanning/:index/detailEdit/:TwoScan',
    name: 'detailEdit',
    component: () => import('@/views/detailEdit'),
    meta: {
      title: '入库修改'
    }
  },
  {
    path: '/testDiff',
    name: 'testDiff',
    component: () => import('@/views/testDiff'),
    meta: {
      title: '入库差异'
    }
  },
  {
    path: '/testDiffOut',
    name: 'testDiffOut',
    component: () => import('@/views/testDiffOut'),
    meta: {
      title: '出库差异'
    }
  },
  {
    path: '/outScanning/:index/:barCodeOutScan',
    name: 'outScanning',
    component: () => import('@/views/outScanning'),
    meta: {
      title: '出库扫描'
    }
  },
  {
    path: '/outScanning/:index/detailOutEdit/:barCodeOutScanChuku',
    name: 'detailOutEdit',
    component: () => import('@/views/detailOutEdit'),
    meta: {
      title: '出库修改'
    }
  },
  {
    path: '/arehousingScanning/:index/:barCode1',
    name: 'arehousingScanning',
    component: () => import('@/views/arehousingScanning'),
    meta: {
      title: '入库扫描'
    }
  },
  {
    path: '/priceCollect',
    name: 'priceCollect',
    component: () => import('@/views/priceCollect'),
    meta: {
      title: '采价'
    }
  },

  {
    path: '/priceCollect_table',
    name: 'priceCollect_table',
    component: () => import('@/views/priceCollect_table'),
    meta: {
      title: '报表查看'
    }
  },
  {
    path: '/priceCollect_add',
    name: 'priceCollect_add',
    component: () => import('@/views/priceCollect_add'),
    meta: {
      title: '新增采价'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home'),
    meta: {
      title: '看板'
    }
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('@/views/form'),
    meta: {
      title: '报表'
    }
  },
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('@/views/supplier'),
    meta: {
      title: '报表详情'
    }
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my'),
    meta: {
      title: '我的'
    }
  },

  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/info'),
    meta: {
      title: '报表详情'
    }
  },
  {
    path: '/point',
    name: 'point',
    component: () => import('@/views/point'),
    meta: {
      title: '盘点'
    }
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/service'),
    meta: {
      title: '殡仪馆管理中心'
    }
  },
  {
    path: '/service1',
    name: 'service1',
    component: () => import('@/views/service1'),
    meta: {
      title: '总部看板'
    }
  },
  {
    path: '/service2',
    name: 'service2',
    component: () => import('@/views/service2'),
    meta: {
      title: '中医看板'
    }
  },
  {
    path: '/service3',
    name: 'service3',
    component: () => import('@/views/service3'),
    meta: {
      title: '患者回访'
    }
  },
  {
    path: '/service5',
    name: 'service5',
    component: () => import('@/views/service5'),
    meta: {
      title: '患者回访'
    }
  },
  {
    path: '/service4',
    name: 'service4',
    component: () => import('@/views/service4'),
    meta: {
      title: '处方明细'
    }
  },
  {
    path: '/service6',
    name: 'service6',
    component: () => import('@/views/service6'),
    meta: {
      title: '处方明细'
    }
  },
  {
    path: '/point_style/:index',
    name: 'point_style',
    component: () => import('@/views/point_style'),
    meta: {
      title: '盘点类型'
    }
  },
  {   
    path: '/point_style/details/:id/:barCode',
    name: 'details',
    component: () => import('@/views/details'),
    meta: {
      title: '详情明细'
    }
  },
  {
    path: '/helper',
    name: 'helper',
    component: () => import('@/views/helper'),
    meta: {
      title: '助手页面'
    }
  },
  {
    path: '/point_style/diff/:id',
    name: 'diff',
    component: () => import('@/views/diff'),
    meta: {
      title: '差异查看'
    }
  },

  {
    path: '/login2',
    name: 'login2',
    component: () => import('@/views2/login'),
    meta: {
      title: '博爱智供'
    }
  },
  {
    path: '/form2',
    name: 'form2',
    component: () => import('@/views2/form'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/index2',
    name: 'index2',
    component: () => import('@/views2/index'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/home2',
    name: 'home2',
    component: () => import('@/views2/home'),
    meta: {
      title: '登录'
    }
  },
  
  {
    path: '/my2',
    name: 'my2',
    component: () => import('@/views2/my'),
    meta: {
      title: '我的'
    }
  },
  {
    path: '/text',
    name: 'text',
    component: () => import('@/views2/text'),
    meta: {
      title: '早报列表'
    }
  },
  {
    path: '/business/:id',
    name: 'business',
    component: () => import('@/views2/business'),
  }, 
  {
    path: '/info2',
    name: 'info2',
    component: () => import('@/views2/info'),
    meta: {
      title: '报表详情'
    }
  },
  {
    path: '/tr',
    name: 'tr',
    component: () => import('@/views2/tr'),
    meta: {
      title: '报表详情'
    }
  },
]

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
 
export default guard(new Router({
  mode:'hash',
  routes
}))
