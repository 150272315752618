import axios from 'axios'
import router from './../router'



//请求拦截器
axios.interceptors.request.use(function (config) {
    // console.log(config,'config');
    config.headers = {
        'token':localStorage.getItem('token'),
        ...config.fromHeader
        // 'content-type':'application/json;charset=UTF-8'
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
    return config
});

//响应拦截器
axios.interceptors.response.use(function (response) {
    if(response.data.msg=='token失效，请重新登录'){//登录失效
        router.replace('./login?pagetype=1');
    }
    return response
});



