
// const LOCALURL = '/api';//代理
// const LOCALURL = 'http://8.142.24.101:10031'

const LOCALURL = 'https://babb.boaiyiyao.com/api' //线上

// const LOCALURL = 'http://192.168.1.106:10031'//本地

// const LOCALURL = 'https://331151y0i6.picp.vip'

const API = {//接口url
    exportExcel:LOCALURL +'/renren-fast/sys/export/export001',
    export001 : LOCALURL + '/renren-fast/sys/export/export001',//注册 Post
    register_api : LOCALURL + '/renren-fast/app/register',//注册 Post
    login_api : LOCALURL + '/renren-fast/app/login',//登录 Post
    menu_api : LOCALURL + '/renren-fast/app/info/menu',//获取当前用户菜单接口 Post
    data_api : LOCALURL + '/renren-fast/app/info/data',//获取当前菜单数据 Post
    salesData_api : LOCALURL + '/renren-fast/app/info/salesData',//获取当前菜单数据 Post
    user_api : LOCALURL + '/renren-fast/app/info/user',//获取当前用户 Post
    view1_api : LOCALURL + '/renren-fast/app/info/view',//获取看版数据 Post
    viewmenu_api : LOCALURL + '/renren-fast/app/info/viewmenu',//获取看版显示名 Post
    curve_api : LOCALURL + '/renren-fast/app/info/curve',//获取曲线图及销售排名数据 Post
    declare_api : LOCALURL + '/renren-fast/app/info/declare',//博爱供应商应付报表
    amountData_api :LOCALURL  + '/renren-fast/app/info/amountData',//以销定结供应商应付报表
    update_api : LOCALURL +  '/renren-fast/app/update',//修改密码
    curve2_api : LOCALURL + '/renren-fast/app/info/curve2',//电商销售排行
    filter_api : LOCALURL + '/renren-fast/app/info/menu/filter',//获取过滤项信息
    filter3_api : LOCALURL + '/renren-fast/app/info/menu/filter/3',//获取下拉框内容
    getShopByArea : LOCALURL + '/renren-fast/user/getShopByArea',//获取区域详情
    functionList:LOCALURL + '/renren-fast/app/v1/function/list',//查询功能列表
    functionInfo:LOCALURL + '/renren-fast/app/v1/function/info/',//查询功能菜单详情
    
    //盘点
    inventoryList:LOCALURL + '/renren-fast/app/v1/inventory/inventoryList',//盘点List
    // detailList:LOCALURL + '/renren-fast/app/v1/inventory/inventoryInfo/',//详情List
    goodsList:LOCALURL + '/renren-fast/app/v1/inventory/queryGoodsInfo',//商品List
    syncRealityQuantityList:LOCALURL + '/renren-fast/app/v1/inventory/syncRealityQuantity',//批号盘点
    differentList:LOCALURL + '/renren-fast/app/v1/inventory/different',//查看差异
    unCalContList:LOCALURL + '/renren-fast/app/v1/inventory/unCalContList',//未盘点数量
    checkAvailable:LOCALURL + '/renren-fast/app/v1/inventory/checkAvailable',//查看差异
    options666:LOCALURL + '/renren-fast/app/v1/inventory/options666',//输入货号返回批号List
    goodsStatisticsList:LOCALURL + '/renren-fast/app/v1/inventory/searchGoods',//输入商品返回商品信息
    goodsDetailList:LOCALURL + '/renren-fast/app/v1/inventory/goodsDetail',//详情
    searchGoods2DetailList:LOCALURL + '/renren-fast/app/v1/inventory/searchGoods2',//采购药品
    savelList:LOCALURL + '/renren-fast/app/v1/inquiry/save',//采购提交
    watchList:LOCALURL + '/renren-fast/app/v1/inquiry/inquiryList',//采购报表
    syncAppendQuantityList:LOCALURL + '/renren-fast/app/v1/inventory/syncAppendQuantity',//详情修改数量接口
    inoutbillList:LOCALURL + '/renren-fast/app/v1/inoutbill/getSupplierList',//供应商列表接口
    inquiryShopList:LOCALURL + '/renren-fast/app/v1/inquiry/inquiryShopList',//采购店铺列表接口
    saveInBillList:LOCALURL + '/renren-fast/app/v1/inoutbill/saveInBill',//入库扫描保存接口
    inoutbillList1:LOCALURL + '/renren-fast/app/v1/inoutbill/inoutbillList',//入出库待提交单据列表
    detailSubList:LOCALURL + '/renren-fast/app/v1/inoutbill/inoutbillInfo',//入出库待提交单据详情接口
    updateInBillList:LOCALURL + '/renren-fast/app/v1/inoutbill/updateInBill',//修改保存入库
    billDifference:LOCALURL + '/renren-fast/app/v1/inoutbill/synVariance',//入库差异接口
    getCustomerList:LOCALURL + '/renren-fast/app/v1/inoutbill/getCustomerList',//出库顾客列表接口
    saveInOutBillList:LOCALURL + '/renren-fast/app/v1/inoutbill/saveOutBill',//出库扫描保存接口
    synVarianceOut:LOCALURL + '/renren-fast/app/v1/inoutbill/synVarianceOut',//出库差异接口
    creditCardQuery:LOCALURL + '/renren-fast/app/v1/setl/list',//医保刷卡查询
    mbView:LOCALURL + '/renren-fast/app/info/view12',//查询慢病销售额   博爱
    mzView:LOCALURL + '/renren-fast/app/info/view13',//查询门诊统筹销售额   博爱
    mbViewQ:LOCALURL + '/renren-fast/app/info/view14',//查询慢病销售额   博爱
    mzViewQ:LOCALURL + '/renren-fast/app/info/view15',//查询门诊统筹销售额   博爱
    //供应商端
    
    getProcessList : LOCALURL + '/renren-fast/app/v1/process/list',//获取业务流程表
    getNewsList:LOCALURL + '/renren-fast/app/v1/news/list',//获取查询早报列表
    getNewsList:LOCALURL + '/renren-fast/app/v1/news/list',//获取查询早报列表
    getHomeBanner:LOCALURL +'/renren-fast/app/v1/homebanner/list',//获取home轮播图列表
    invoiceData:LOCALURL +'/renren-fast/info/invoiceData',//查询供应商入库未到发票数据
    // 服务中心
    feeRegistration:LOCALURL +'/renren-fast/app/info/feeRegistration',//收费登记
    getDataComparison:LOCALURL +'/renren-fast/app/info/getDataComparison',//收费登记
    feeRegistrationDetail:LOCALURL +'/renren-fast/app/info/feeRegistrationDetail',//收费登记明细
    //中科医院
    getHisData:LOCALURL +'/renren-fast/app/info/getHisData',//收费登记明细
    getTransactionList:LOCALURL +'/renren-fast/app/info/getTransactionList',//查询收费登记列表
    getDrugDetail:LOCALURL +'/renren-fast/app/info/getDrugDetail',//查询诊疗/药品收费明细
    getExaminationDetail:LOCALURL +'/renren-fast/app/info/getExaminationDetail',//查询检查收费明细列表
    getChineseRecipe:LOCALURL +'/renren-fast/app/info/getChineseRecipe',//查询中医开方列表
    getPerformance:LOCALURL +'/renren-fast/app/info/getPerformance',//查询中医绩效列表
    getPrescriptionData:LOCALURL +'/renren-fast/app/info/getPrescriptionData',//查询门店处方汇总
    getPrescriptionDetail:LOCALURL +'/renren-fast/app/info/getPrescriptionDetail',//查询门店处方汇总
    getFollowUpInfo:LOCALURL +'/renren-fast/app/info/getFollowUpInfo',//患者访问列表
    followUp:LOCALURL +'/renren-fast/app/info/followUp',//回访确认
    getRecipePng:LOCALURL +'/renren-fast/app/info/getRecipePng',//查看处方
}

module.exports = API
